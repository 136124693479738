<template>
  <Form
    :submit="handleSubmit"
    :initialValues="prepareInitialValues(initialValues)"
    @change="handleFormChange"
  >
      <loader :loading="loading" :backdrop="true" />
      <div class="form-narrow">
        <label class="form-section-title">main information</label>

        <div class="form-row">
          <TextField name="person_id" label="person id*" :validate="composeValidators(required, uuid)" />
          <div class="form-col">
            <label>relationship type*</label>
            <p class="w-full bg-gray-200 p-2 text-gray-400 font-bold">{{role}}</p>
          </div>
        </div>
        <div class="form-row">
          <TextField name="lease_id" label="lease id*" :validate="composeValidators(required, uuid)" />
        </div>

        <label class="form-section-title mt-8">supporting information</label>

        <div class="form-row supporting-entity-option">
          <TextField name="community_id" label="community id*" :validate="composeValidators(required, uuid)" />
        </div>
        <ModalFooter :footer="footer" />
      </div>
  </Form>
</template>
<script>
  import ModalNavigation from "@/mixins/ModalNavigation";
  import ValidatorMixin from "@/components/form/ValidatorMixin";
  import NotifyMixin from "@/mixins/NotifyMixin";
  import Form from "@/components/form/Form";
  import Loader from '@/components/ui/Loader';
  import TextField from "@/components/form/TextField";
  import ModalFooter from "@/components/ui/modals/ModalFooter";
  import ProfilesMixin from "@/views/auth/raa-devtools/profiles/ProfilesMixin.js";
import { mapGetters, mapActions } from 'vuex';

  export default {
    name: "ProfileForm",
    components: {
      TextField,
      ModalFooter,
      Form,
      Loader
    },
    mixins: [
      ModalNavigation,
      ValidatorMixin,
      NotifyMixin,
      ProfilesMixin,
    ],
    data() {
      return {
        loading: false,
        community_id: null,
        lease_id: null,
        person_id: null,
        types: [],
        communities: [],
        useManualIdInput: false,
        formValues: {},
        footer: {
          primaryButton: 'send credentials',
        },
        initialValues: {},
        role: ''
      }
    },
    mounted() {
        Promise.all([
            this.fetchCommunities(),
            this.fetchLeases(),
            this.fetchMemberships(),
            this.fetchPersons(),
            this.fetchRelationshipTypes(),
        ]);
    },
    computed: {
      ...mapGetters({
        getFutureGuarantor: 'application_service/getFutureGuarantor',
        getFutureApplicant: 'application_service/getFutureApplicant',
        getApplication: 'application_service/getApplication',
        getcurrentApplicant: 'application_service/getcurrentApplicant'
      }),
    },
    methods: {
      ...mapActions({
        setApplication: 'application_service/setApplication',
      }),
      handleFormChange(state) {
        this.formValues = state.values;
      },

      fetchRelationshipTypes() {
        this.$raaDataProvider.getList('relationshipTypes', {size: 999})
          .then((result) => {
              this.types = result.map(({type}) => ({key: type, value: type}));
          })
          .catch((err) => this.notifyError(err.message));
      },

      fetchCommunities() {
        this.$authDataProvider.getList('communities', { size: 999 })
          .then(({content}) => {
            this.communities = content.filter(({deletedAt}) => !deletedAt)
              .sort((a, b) => a.customerName.localeCompare(b.customerName))
              .map(({communityId: key, communityName: value}) => ({
                key,
                value
              }));
          })
          .catch(error => this.notifyError(error.message));
      },

      prepareInitialValues(initialValues = {}) {
        return {
          ...initialValues,
          person_id: this.person_id,
          lease_id: this.lease_id,
          community_id: this.community_id,
          supportEntity: 'communityId',
        }
      },

      handleSubmit({ supportEntity, community_id, person_id, lease_id, relationshipType = 'guarantor' }) {
        if (supportEntity === 'communityId' && !community_id) {
          this.notifyError('You must specify community id.');
          return;
        }
        this.loading = true;

        const supportingData = { communityId: community_id };

        this.$raaDataProvider.create('profiles', {
          data: {
            personId: person_id,
            leaseId: lease_id,
            relationshipType: this.role === 'applicant' ? 'applicant' : relationshipType,
            ...supportingData,
          }
        })
        .then(() => {
          if (this.role === 'applicant') {
            const applicants = this.getApplication.applicants;
            applicants.push({...this.getFutureApplicant});
            this.setApplication({
              ...this.getApplication,
              applicants: applicants
            });
          } else {
            this.setApplication({
              ...this.getApplication,
              applicants: this.getApplication.applicants.map(applicant => {
                if (applicant.id === this.getcurrentApplicant.id) {
                  return {
                    ...applicant,
                    is_guarantor_needed: false,
                    applicant_status: 'pending guarantor',
                    guarantor: {...this.getFutureGuarantor}
                  };
                }
                return applicant;
              })
            });
          }
          this.notifySuccess(`the ${this.role} profile has been succefully created`);
          this.$router.push({ name: 'manage_application.details' });
        })
        .catch(() => {
          this.loading = false;
          this.notifyError('something went wrong creating the profile');
        })
        .finally(() => this.loading = false);
      },
    },
    beforeMount() {
      if (this.$route.params?.role) this.role = this.$route.params.role;
      if (this.getApplication) {
        this.community_id = this.getApplication.community;
        this.lease_id = this.getApplication.leaseId;
      }
      if (this.role === 'applicant') this.person_id = this.getFutureApplicant.id;
      else this.person_id = this.getFutureGuarantor.id;
    }
  }
</script>

<style scoped>
  .supporting-entity-option {
    flex-direction: column;
  }
</style>